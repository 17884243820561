module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b3402b9741c44676972ea8ce52a4f203@o4504649894330368.ingest.sentry.io/4504649895247872","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["Strict-Transport-Security: max-age=31536000; includeSubDomains; preload"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","fallbackLanguage":"en-US","defaultLanguage":"en-US","languages":[],"generateDefaultLanguagePage":false,"redirect":false,"i18nextOptions":{"fallbackLng":"en-US","supportedLngs":["en-US","fr-FR","de-DE","nl-NL","es-ES","ca-ES","it-IT","pt-BR","pl-PL","et-EE","ru-RU","id-ID","th-TH","vi-VN","ko-KR","zh-CN","ja-JP","bg-BG","cs-CZ","hr-HR","da-DK","el-GR","fi-FI","ga-IE","hu-HU","lt-LT","lv-LV","mt-MT","pt-PT","ro-RO","sk-SK","sl-SI","sv-SE","cy-GB"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-K0SV3L4FG3"],"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2aqgiznSwEkv9LGEVzEG6RFQbQf2jYcZ","devKey":"XXEmpCNsHg5PMipM5XeHIjeR84Du8Sez","trackPage":false,"trackPageImmediately":false,"trackPageOnlyIfReady":false,"trackPageOnRouteUpdate":false,"trackPageOnRouteUpdateDelay":50,"trackPageWithTitle":false,"delayLoad":false,"delayLoadDelay":1000,"delayLoadUntilActivity":false,"delayLoadUntilActivityAdditionalDelay":0,"manualLoad":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
